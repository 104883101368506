import React, { useState, useEffect, useContext } from 'react';
import './Search.css';
import AdvancedSearch from './AdvancedSearch';
import { useNavigate } from 'react-router-dom';
import { DataContext } from '../assets/DataContext';

// Add the parseDescription function
const parseDescription = (description) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(description, 'text/html');
  const rows = doc.querySelectorAll('tr');

  
  const properties = {};
  rows.forEach((row) => {
    const cells = row.querySelectorAll('th, td');
    if (cells.length === 2) {
      const key = cells[0].textContent.trim().toLowerCase().replace(/ /g, '_');
      const value = cells[1].textContent.trim();
      properties[key] = value;
    }
  });

  return properties;
};

const Search = ({ setSelectedFeature, onTabChange }) => {
  const [activeSearchTab, setActiveSearchTab] = useState('standard');
  const [searchResults, setSearchResults] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isSearchTriggered, setIsSearchTriggered] = useState(false); // Track if search has been triggered
  const navigate = useNavigate();
  const { transformedOwnershipData, loading } = useContext(DataContext);  // Use pre-transformed data
  
  // Function to search the transformed data
  const searchTransformedData = (query, transformedData) => {
    const lowerCaseQuery = query.toLowerCase().trim();

    return transformedData.filter(feature => {
      const properties = feature.properties || {};

      const owner = properties.owner?.toLowerCase().trim() || '';
      const address = properties.address?.toLowerCase().trim() || '';
      const pidn = properties.pidn?.toLowerCase().trim() || '';
      const tax_id = properties.tax_id?.toLowerCase().trim() || '';

      return (
        owner.includes(lowerCaseQuery) ||
        address.includes(lowerCaseQuery) ||
        pidn.includes(lowerCaseQuery) ||
        tax_id.includes(lowerCaseQuery)
      );
    });
  };

  // Handle search input
  const handleSearch = () => {
    setIsSearchTriggered(true); // Mark that search has been triggered
    const results = searchTransformedData(searchQuery, transformedOwnershipData);  // Use transformed data for search
    setSearchResults(results);
  };

  // Handle "Map It" button click
  const handleMapClick = (result) => {
    setSelectedFeature(result); // Set the selected feature
    onTabChange('map'); // Manually set the tab to 'map'
    navigate('/map'); // Navigate to the map view
  };

  // Add event listener for the "Enter" key to trigger the search
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        handleSearch(); // Trigger the search when "Enter" is pressed
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [searchQuery]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="search-container">
      <div className="search-tabs">
        <button
          className={activeSearchTab === 'standard' ? 'active' : ''}
          onClick={() => setActiveSearchTab('standard')}
        >
          Standard Search
        </button>
        <button
          className={activeSearchTab === 'advanced' ? 'active' : ''}
          onClick={() => setActiveSearchTab('advanced')}
        >
          Advanced Search
        </button>
      </div>
      <div className="search-content">
        {activeSearchTab === 'standard' && (
          <div>
            <div className="search-bar-button-container">
              <input 
                type="text" 
                value={searchQuery} 
                onChange={(e) => setSearchQuery(e.target.value)} 
                placeholder="Search by owner, address, PIDN..."
              />
              <button onClick={handleSearch}>Search</button>
            </div>
            {isSearchTriggered && searchResults.length === 0 && (
              <div className="no-results">No results found.</div>
            )}
            <ul className="search-results-list">
              {searchResults.map((result, index) => {
                // Parse the description to extract additional details
                const parsedProperties = parseDescription(result.properties.description || '');

                const taxLink = `https://gis.tetoncountywy.gov/portal/apps/dashboards/5574848e46464109a14dead33e5ddace#ParcelInfo=${parsedProperties.tax_info}`;
                const clerkLink = `https://gis.tetoncountywy.gov/portal/apps/dashboards/03ef10d8b8634909b6263e9016bcc986#statepin=${parsedProperties.pidn}`;



                return (
                  <li key={index} className={`search-result-item ${index % 2 === 0 ? 'even' : 'odd'}`}>
                    <div className="result-content">
                    <div className="result-details">
                      {/* Standard properties */}
                      
                      <strong>Owner:</strong> {result.properties.owner || 'N/A'}<br/>
                      <strong>Address:</strong> {result.properties.address 
                        ? `${result.properties.address}${parsedProperties.owner_city ? ', ' + parsedProperties.owner_city : ''}${parsedProperties.owner_state ? ', ' + parsedProperties.owner_state : ''}` 
                        : 'N/A'}<br/>
                      <strong>PIDN:</strong> {result.properties.pidn || 'N/A'}<br/>
                       {/* Deed information */}
                      <strong>Deed:</strong> {parsedProperties.deed || 'N/A'}<br/>

                        {/* Area information */}
                      
                    </div>


                      <div className="result-buttons">
                        <div className="result-buttons-grid">
                            {/* Map button */}
                            <button className="map-it-button" onClick={() => handleMapClick(result)}>Map</button>

                            {/* Detail button */}
                            <button className="detail-button" onClick={() => window.open(`https://gis.tetoncountywy.gov/portal/apps/dashboards/ca93f7b7ae3e4d51ad371121a64ee739#accountno=${result.properties.accountno}`, '_blank')}>
    Detail
  </button>
  <button className="tax-button" onClick={() => window.open(taxLink, '_blank')}>
    Tax
  </button>
  <button className="clerk-button" onClick={() => window.open(clerkLink, '_blank')}>
    Clerk
  </button>
                        </div>

                      </div>
                    </div>
                    <hr />
                  </li>
                );
              })}
            </ul>
          </div>
        )}

        {activeSearchTab === 'advanced' && (
          <AdvancedSearch />  // Render AdvancedSearch component
        )}
      </div>
    </div>
  );
};

export default Search;
